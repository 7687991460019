import './index.css'

const Banner = () => {
    return ( 
        <section className= "banner">  
        <div>
            <h1>WELCOME</h1>
            <p>TO THE NEXT CHAPTER IN CONSTRUCTION</p>
            <button>KNOW MORE</button>
        </div>
        </section>
        

     );
}
 
export default Banner;
