import './index.css';

const OSC = () => {
    return ( 
        <section className="clients">
            <div className="clientdescription">
                <h1>Our Satisfied <span>Clients</span></h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum eius qui, repellat placeat porro tempora doloremque omnis quis autem sint ab at, id quidem. Voluptatibus eaque adipisci unde odio id. Voluptatibus eaque adipisci unde odio id. Voluptatibus eaque adipisci unde odio id.
                </p>
            </div>

            <div className="clientimg">
                <img src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/corporate-company-logo-design-template-2402e0689677112e3b2b6e0f399d7dc3_screen.jpg?ts=1561532453" alt="" />
                <img src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/corporate-company-logo-design-template-2402e0689677112e3b2b6e0f399d7dc3_screen.jpg?ts=1561532453" alt="" />
                <img src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/corporate-company-logo-design-template-2402e0689677112e3b2b6e0f399d7dc3_screen.jpg?ts=1561532453" alt="" />
                <img src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/corporate-company-logo-design-template-2402e0689677112e3b2b6e0f399d7dc3_screen.jpg?ts=1561532453" alt="" />
            </div>
        </section>
     );
}
 
export default OSC;
